import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, registerables } from "chart.js";
import { Tab, Tabs } from "react-bootstrap";
import { Table, BarChartFill } from "react-bootstrap-icons";
import SpecificPassPercentage from "./SpecificPassPercentage";

ChartJS.register(...registerables, ChartDataLabels);

function PercentageView() {
  const [passPercentages, setPassPercentages] = useState([]);
  const { projectId } = useContext(ProjectContext);

  const fetchPassPercentage = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/pass-percent`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            projectId: projectId,
          },
        }
      );

      setPassPercentages(response.data);
    } catch (error) {
      console.error("Error fetching pass percentage:", error);
    }
  };
  useEffect(() => {
    fetchPassPercentage();
  }, []);
  const truncateLabel = (label, maxLength) => {
    return label.length > maxLength
      ? label.substring(0, maxLength) + "..."
      : label;
  };

  const maxLength = 10;

  // Extract full labels and parent values
  const fullLabels = passPercentages.map((item) => item.issueResolved);

  // Prepare chart data
  const chartData = {
    labels: passPercentages.map((item) =>
      truncateLabel(item.issueResolved, maxLength)
    ),
    fullLabels, // Store full labels for tooltip
    extraData: passPercentages.map((item) => ({
      zeroIssuesLeft: item.zeroIssuesLeft,
      oneIssueLeft: item.oneIssueLeft,
      twoIssuesLeft: item.twoIssuesLeft,
      threePlusIssuesLeft: item.threePlusIssuesLeft,
      passPercentages: item.passPercentages,
    })),
    datasets: [
      {
        label: "Pass Percentage",
        data: passPercentages.map((item) => item.passPercentages),
        backgroundColor: "#4e73df",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: true },
      title: { display: false },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return fullLabels[index]; // Show full label in tooltip
          },
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            const extra = chartData.extraData[index]; // Get additional data
            return [
              `0 Issues Left: ${extra.zeroIssuesLeft}`,
              `1 Issue Left: ${extra.oneIssueLeft}`,
              `2 Issues Left: ${extra.twoIssuesLeft}`,
              `3+ Issues Left: ${extra.threePlusIssuesLeft}`,
              `Pass %: ${extra.passPercentages}%`,
            ];
          },
        },
      },
      datalabels: {
        color: "#fff",
        align: "start",
        anchor: "end",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        title: { display: true, text: "Percentage %" },
      },
      x: {
        title: { display: true, text: "Issue Resolved Tags" },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
        <div className="row">
        <div className="col-md-8">
          <div
            className="shadow p-3 bg-white rounded" 
          >
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
        <div className="col-md-4">
          <SpecificPassPercentage />
        </div>
      </div>
      <div className="table-responsive border rounded p-2 mt-3">
        <table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th>Issue Resolved</th>
              <th>0 Issues Left</th>
              <th>1 Issue Left</th>
              <th>2 Issues Left</th>
              <th>3+ Issues Left</th>
              <th>Pass %</th>
            </tr>
          </thead>
          <tbody>
            {passPercentages.map((passPercentage, index) => (
              <tr key={index}>
                <td>{passPercentage.issueResolved}</td>
                <td>{passPercentage.zeroIssuesLeft}</td>
                <td>{passPercentage.oneIssueLeft}</td>
                <td>{passPercentage.twoIssuesLeft}</td>
                <td>{passPercentage.threePlusIssuesLeft}</td>
                <td>{passPercentage.passPercentages}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      

      {/* <Tabs defaultActiveKey="table" className="mb-3">
        <Tab
          eventKey="table"
          title={
            <span>
              <Table size={20} />
            </span>
          }
        >
          <SpecificPassPercentage />
          <div className="table-responsive border rounded p-2">
            <table className="table table-borderless table-striped">
              <thead>
                <tr>
                  <th>Issue Resolved</th>
                  <th>0 Issues Left</th>
                  <th>1 Issue Left</th>
                  <th>2 Issues Left</th>
                  <th>3+ Issues Left</th>
                  <th>Pass %</th>
                </tr>
              </thead>
              <tbody>
                {passPercentages.map((passPercentage, index) => (
                  <tr key={index}>
                    <td>{passPercentage.issueResolved}</td>
                    <td>{passPercentage.zeroIssuesLeft}</td>
                    <td>{passPercentage.oneIssueLeft}</td>
                    <td>{passPercentage.twoIssuesLeft}</td>
                    <td>{passPercentage.threePlusIssuesLeft}</td>
                    <td>{passPercentage.passPercentages}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab
          eventKey="chart"
          title={
            <span>
              <BarChartFill size={20} />
            </span>
          }
        >
          <Bar data={chartData} options={chartOptions} />
        </Tab>
      </Tabs> */}
    </div>
  );
}

export default PercentageView;
