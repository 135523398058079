import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  isLoggedIn,
  getCurrentUser,
  getCurrentToken,
} from "../services/cognito/cognitoAuth";
import MainLayout from "../layout/Layout";
import Users from "../pages/Users";
import FileList from "../pages/FileList";
import Projects from "../pages/Projects";
import ProjectContext from "../ProjectContext";
import axios from "axios";
import FileViewer from "../pages/FileViewer";
import ImportDocuments from "../pages/ImportDocuments";
import Documents from "../pages/Documents";
import FileLog from "../pages/FileLog";
import Dashboard from "../pages/Dashboard";
import TitleList from "../pages/Titles";
import AddIssue from "./issues/AddIssues";
import IssueList from "../pages/IssueList";
import EditIssue from "./issues/EditIssue";
import ModelList from "../pages/ModelList";
import TenantsList from "../pages/Tenants";
import PresetTag from "../pages/PresetTag";
import AddPresetTag from "./tags/AddPesetTag";
import EditPresetTag from "./tags/EditPresetTag";
import { fetchUserInfo } from "../api/apiService";

function AuthenticatedRoutes() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    setProjectId,
    setProjectName,
    setUserId,
    setRoles,
    setGroupId,
    setTenantId,
    setUserGroupId,
    setProjectType,
    setTheme,
    roles,
  } = useContext(ProjectContext);

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch user and project info
  const fetchData = async (cognitoSub, token) => {

    if (cognitoSub && token) {
      try {
        if (!loading) return; // Prevent multiple calls
        const data = await fetchUserInfo(cognitoSub, token);
        
        // Update state in the component
        setProjectId(data.currentProject.id);
        setProjectName(data.currentProject.name);
        setUserId(data.id);
        setTheme(data.isDarkThemeActive ? "dark" : "light");

        const userGroups = data.userGroups;
        if (userGroups.length > 0) {
          setProjectType(userGroups[0].projectType);
        }

        let userRoles;
        if (data.currentProject.id === null) {
          const lastAccessedGroup = userGroups.reduce((latest, current) =>
            !latest || new Date(current.lastAccessedOn) > new Date(latest.lastAccessedOn)
              ? current
              : latest
          );
          userRoles = lastAccessedGroup ? [lastAccessedGroup.group] : [];
        } else {
          userRoles = userGroups
            .filter((group) => group.projectId === data.currentProject.id)
            .map((group) => group.group);
        }

        setRoles(userRoles);

        const currentProjectGroup = userGroups.find(
          (group) => group.projectId === data.currentProject.id
        );

        if (currentProjectGroup) {
          setGroupId(currentProjectGroup.groupId);
          setTenantId(currentProjectGroup.tenantId);
          setUserGroupId(currentProjectGroup.userGroupId);
        }

        // Handle redirection logic
        if (firstLoad && location.pathname === "/login") {
          const roleRedirects = {
            SuperAdmin: "/users",
            Developer: "/import-documents",
            TenantAdmin: "/users",
            Reviewer: "/dashboard",
            ProjectAdmin: "/dashboard",
          };

          const userRole = Object.keys(roleRedirects).find((role) =>
            userRoles.includes(role)
          );

          navigate(userRole ? roleRedirects[userRole] : "/dashboard", { replace: true });

          setFirstLoad(false);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeAuthentication = async () => {
      try {
        const loggedIn = await isLoggedIn();
        setAuthenticated(loggedIn);

        if (loggedIn) {
          const user = await getCurrentUser();
          const token = await getCurrentToken();
          setCognitoSub(user.sub);
          setAccessToken(token);

          await fetchData(user.sub, token);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error initializing authentication:", error);
        setLoading(false);
      }
    };

    initializeAuthentication();
  }, []);
  useEffect(() => {
    if (roles.includes("ProjectAdmin") && location.pathname === "/files") {
      navigate("/documents", { replace: true });
    } else if (
      roles.includes("Reviewer") &&
      location.pathname === "/documents"
    ) {
      navigate("/files", { replace: true });
    }
  }, [roles, location.pathname, navigate]);

  if (loading) return <div>Loading...</div>;

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/dashboard"
          element={
            <RoleProtectedRoute allowedRoles={["Reviewer", "ProjectAdmin"]}>
              <Dashboard />
            </RoleProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <RoleProtectedRoute
              allowedRoles={["SuperAdmin", "ProjectAdmin", "TenantAdmin"]}
            >
              <Users />
            </RoleProtectedRoute>
          }
        />

        <Route
          path="/files"
          element={
            <RoleProtectedRoute
              allowedRoles={["Reviewer"]}
            >
              <FileList />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <RoleProtectedRoute
              allowedRoles={["TenantAdmin"]}
            >
              <Projects />
            </RoleProtectedRoute>
          }
        />
        <Route path="/file-viewer" element={<FileViewer />} />
        <Route
          path="/import-documents"
          element={
            <RoleProtectedRoute
              allowedRoles={["ProjectAdmin", "Developer"]}
            >
              <ImportDocuments />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/documents"
          element={
            <RoleProtectedRoute
              allowedRoles={["ProjectAdmin", "Developer"]}
            >
              <Documents />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/action-log"
          element={
            <RoleProtectedRoute
              allowedRoles={["ProjectAdmin", "Reviewer"]}
            >
              <FileLog />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/title-list"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin"]}>
              <TitleList />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/issues"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer", "Developer"]}>
              <IssueList />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/add-issue"
          element={
            <RoleProtectedRoute allowedRoles={["Reviewer"]}>
              <AddIssue />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/edit-issue/:id"
          element={
            <RoleProtectedRoute allowedRoles={["Reviewer"]}>
              <EditIssue />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/model-list"
          element={
            <RoleProtectedRoute allowedRoles={["SuperAdmin"]}>
              <ModelList />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/tenants-list"
          element={
            <RoleProtectedRoute allowedRoles={["SuperAdmin"]}>
              <TenantsList />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/preset-tag"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer"]}>
              <PresetTag />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/add-preset-tag"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer"]}>
              <AddPresetTag />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/edit-preset-tag/:id"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer"]}>
              <EditPresetTag />
            </RoleProtectedRoute>
          }
        />
      </Route>

      {/* Fallback */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}

// Role-protected route component
const RoleProtectedRoute = ({ allowedRoles, children }) => {
  const { roles: userRoles } = useContext(ProjectContext); // Access user roles from ProjectContext
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    if (!userRoles || userRoles.length === 0) {
      setHasAccess(false); // Roles are not yet loaded
      return;
    }

    // Check if the user has access to the current route
    const access = allowedRoles.some((role) => userRoles.includes(role));
    if (!access) {
      // Find the highest priority role and redirect
      const roleRedirects = {
        SuperAdmin: "/users",
        Developer: "/import-documents",
        TenantAdmin: "/users",
        Reviewer: "/dashboard",
        ProjectAdmin: "/dashboard",
      };

      const userRole = Object.keys(roleRedirects).find((role) =>
        userRoles.includes(role)
      );

      if (userRole) {
        navigate(roleRedirects[userRole], { replace: true });
      } else {
        navigate("/dashboard", { replace: true }); // Default fallback
      }
    }

    setHasAccess(access);
  }, [allowedRoles, userRoles, navigate]);

  // Show a loading spinner or block rendering until access is determined
  if (hasAccess === null) {
    return <div>Loading...</div>;
  }

  // If the user has access, render the children
  if (hasAccess) {
    return children;
  }

  // If redirected, render nothing
  return null;
};

export default AuthenticatedRoutes;
