import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  Folder,
  FileEarmark,
  ChevronDoubleLeft,
  ChevronRight,
  ChevronDown,
  ArrowsAngleExpand,
  ArrowsAngleContract,
  Download,
  XCircleFill,
  ChevronUp,
  CaretRightFill,
  CaretLeftFill,
} from "react-bootstrap-icons";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { ZoomInIcon, ZoomOutIcon, zoomPlugin } from "@react-pdf-viewer/zoom";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import axios from "axios";
import ProjectContext from "../ProjectContext";
import ReactJson from "react-json-view";
import { Rnd } from "react-rnd";
import Select from "react-select";
import JsonViewer from "../components/jsonViewer/CustomJsonViewer";

function FileViewer() {
  const location = useLocation();
  const navigate = useNavigate();
  const filePathJson = location.state ? location.state.filePathJson : "{}";

  // Parse the JSON string into an object
  const fileTree = JSON.parse(filePathJson);
  // State variable for the Blob URL
  const [blobURL, setBlobURL] = useState(null);
  const [jsonContent, setJsonContent] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedFileContent, setClickedFileContent] = useState(null);
  const [clickedFileKey, setClickedFileKey] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFirstColumnExpanded, setIsFirstColumnExpanded] = useState(false);
  const [isSecondColumnExpanded, setIsSecondColumnExpanded] = useState(false);
  const [isThirdColumnVisible, setIsThirdColumnVisible] = useState(false);
  const { roles, userId, projectId, projectType } = useContext(ProjectContext);
  const processedFileURL = location.state
    ? location.state.processedFileURL
    : null;
  const pdfURL = location.state ? location.state.fileURL : null;
  const fileId = location.state ? location.state.id : null;
  const status = location.state ? location.state.status : null;
  const currentPage = location.state ? location.state.currentPage : null;
  const fileName = location.state ? location.state.name : null;
  const files = location.state ? location.state.files : null;
  const [reviewCompleted, setReviewCompleted] = useState(false);
  const [lastFileId, setLastFileId] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [pdfView, setPdfView] = useState(false);

  // State for floating modal
  const [draggableDivVisible, setDraggableDivVisible] = useState(false);
  const [draggableFileType, setDraggableFileType] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [popUpZoomKey, setPopUpZoomKey] = useState(0); // State to trigger zoom reset for popup image
  const transformWrapperRef = useRef(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [issues, setIssues] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  // Get file details from state
  const { id } = location.state || {};
  // Ensure files is not null before finding index
  const initialIndex = files?.length
    ? files.findIndex((file) => file.id === id)
    : -1;

  // Default index to 0 if not found
  const [currentIndex, setCurrentIndex] = useState(
    initialIndex >= 0 ? initialIndex : 0
  );

  // Get current file details safely
  const currentFile = files?.[currentIndex] || null;

  // Navigate to the next or previous file in the array order
  const navigateToFile = (newIndex) => {
    if (files?.length && newIndex >= 0 && newIndex < files.length) {
      const newFile = files[newIndex];

      // Parse the new filePathJson safely
      const updatedFilePathJson = newFile?.filePathJson || "{}";

      setCurrentIndex(newIndex);
      navigate("/file-viewer", {
        state: {
          ...newFile, // Pass next/previous file details
          files, // Keep the full list
          filePathJson: updatedFilePathJson, // ✅ Ensure updated filePathJson
        },
      });

      // ✅ Fetch the new JSON file after navigation
      const jsonFileKey = findJsonFileKey(JSON.parse(updatedFilePathJson));
      if (jsonFileKey) {
        fetchDefaultFile(jsonFileKey);
      }

      // ✅ Fetch the new PDF or other file after navigation
      const newPdfKey = findPdfFileKey(JSON.parse(updatedFilePathJson));
      if (newPdfKey) {
        fetchFile(newPdfKey);
      }
    }
  };

  const nextFile = () => navigateToFile(currentIndex + 1);
  const prevFile = () => navigateToFile(currentIndex - 1);

  // Function to handle file clicks
  const handleFileClick = (file, filetype) => {
    if (filetype === "jpg" || filetype === "pdf" || filetype === "png") {
      // Set the content or URL of the draggable div based on the clicked file

      setDraggableFileType(filetype);
      setDraggableDivVisible(true);
      setSelectedFileName(file.split("/").pop());
      setPopUpZoomKey((prevKey) => prevKey + 1);
      getFileDetails(file, filetype);
    }
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLastFileId(fileId);
  }, [fileId]);
  const fetchDefaultFile = async (key) => {
    setIsLoading(true);

    try {
      const token = await getCurrentToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/get-file`,
        { key: decodeURIComponent(key) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const signedUrl = response.data;

      // Fetch the actual file content from the signed URL
      const fileResponse = await axios.get(signedUrl);
      const fileContent = fileResponse.data;

      setJsonContent(fileContent); // use the file content
      setIsLoading(false);
    } catch (err) {
      console.log(err, err.stack);
      setIsLoading(false);
    }
  };

  const getFileDetails = async (key, filetype) => {
    try {
      const token = await getCurrentToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/get-file`,
        { key: decodeURIComponent(key) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClickedFileContent(response.data);
    } catch (err) {
      console.log(err, err.stack);
    }
  };

  const fetchFile = async (key) => {
    // setIsLoading(true);
    setDraggableDivVisible(false);
    setClickedFileKey(key);

    const fileType = key.split(".").pop();
    setFileType(fileType);

    try {
      const token = await getCurrentToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/get-file`,
        { key: decodeURIComponent(key) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (fileType === "json") {
        setIsLoading(true);
        const signedUrl = response.data;
        // Fetch the actual file content from the signed URL
        const fileResponse = await axios.get(signedUrl);
        const fileContent = fileResponse.data;

        //setBlobURL(null); // No blob URL for JSON files
        // console.log(fileContent);
        setClickedFileContent(fileContent);
        setIsLoading(false);
      } else {
        setBlobURL(response.data);
        setClickedFileContent(response.data);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err, err.stack);
      setIsLoading(false);
    }
  };
  const fetchPdfFile = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/get-file`,
        { key: decodeURIComponent(pdfURL) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfView(response.data); // This should be a valid URL
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  useEffect(() => {
    if (projectType === "PREVIEW_PROJECT" && pdfURL) {
      fetchPdfFile();
    }
  }, [pdfURL, projectType]);

  const findJsonFileKey = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === "string" && key.endsWith(".json")) {
        return obj[key];
      } else if (typeof obj[key] === "object") {
        const result = findJsonFileKey(obj[key]);
        if (result) return result;
      }
    }
    return null;
  };
  useEffect(() => {
    const jsonFileKey = findJsonFileKey(fileTree);
    if (jsonFileKey) {
      fetchDefaultFile(jsonFileKey);
    }
  }, []);
  const findPdfFileKey = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === "string" && key.endsWith(".pdf")) {
        return obj[key];
      }
    }
    return null;
  };
  useEffect(() => {
    const pdfFileKey = findPdfFileKey(fileTree);
    if (pdfFileKey) {
      fetchFile(pdfFileKey);
    }
  }, []);

  const toggleFolder = (path, isOutput) => {
    setExpandedFolders((prev) => {
      if (isOutput) {
        // Collapse all folders and expand only the specified one
        const newExpandedFolders = Object.keys(prev).reduce((acc, key) => {
          // Ensure that the current folder remains expanded
          if (key === path) {
            acc[key] = !prev[key];
          } else {
            acc[key] = false;
          }
          return acc;
        }, {});
        // Expand the specified folder
        if (path === "output") {
          newExpandedFolders[path] = true;
        } else {
          newExpandedFolders[path] = !prev[path];
        }
        return newExpandedFolders;
      } else {
        // Toggle the specific folder
        return {
          ...prev,
          [path]: !prev[path],
        };
      }
    });
  };

  const buildFileTree = (obj, path = "") => {
    return Object.entries(obj).map(([key, value]) => {
      const newPath = path ? `${path}/${key}` : key;
      if (typeof value === "string") {
        // Value is a file URL
        if (!key.endsWith(".zip") && !key.endsWith(".json")) {
          return (
            <li key={newPath} className="pure-tree_link">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (newPath.startsWith("output")) {
                    handleFileClick(value, key.split(".").pop());
                  } else {
                    fetchFile(value);
                  }
                  //
                  // Pass file URL and type
                }}
                rel="noopener noreferrer"
              >
                <FileEarmark /> {key}
              </a>
            </li>
          );
        }
      } else {
        // Value is an object (folder)
        if (!key.endsWith(".zip")) {
          // Ensure "output" folder is expanded initially
          if (key === "output" && expandedFolders[newPath] === undefined) {
            toggleFolder(newPath, true);
          }
          return (
            <li key={newPath}>
              {key === "output" && (
                <label
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>Output</span>
                  <span
                    onClick={() => toggleFolder(newPath, true)}
                    className="arrow-icon"
                  >
                    {expandedFolders[newPath] ? (
                      <ArrowsAngleContract />
                    ) : (
                      <ArrowsAngleExpand />
                    )}
                  </span>
                </label>
              )}
              {key !== "output" && (
                <label onClick={() => toggleFolder(newPath, false)}>
                  <span className="arrow-icon">
                    {expandedFolders[newPath] ? (
                      <ChevronDown />
                    ) : (
                      <ChevronRight />
                    )}
                    {"    "}
                    <Folder /> {key}
                  </span>
                </label>
              )}
              {expandedFolders[newPath] && (
                <ul className="pure-tree">{buildFileTree(value, newPath)}</ul>
              )}
            </li>
          );
        }
      }
    });
  };

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  // Second instance for a draggable modal
  const popupzoomPluginInstance = zoomPlugin();
  const {
    ZoomInButton: ZoomInButtonPopup,
    ZoomOutButton: ZoomOutButtonPopup,
    ZoomPopover: ZoomPopoverPopup,
  } = popupzoomPluginInstance;

  const completeReview = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/status-update
        `,
        {
          fileId: fileId,
          userId: userId,
          status: "NOISSUE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReviewCompleted(true);
      // Set the message
      setMessage("No Issue Found successfully!");

      // Hide the message after 3 seconds
      setTimeout(() => {
        setMessage("");
        navigate("/files");
      }, 1000);
    } catch (error) {
      console.error("Error completing review:", error);
    }
  };
  const handleReviewCompleted = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/status-update
        `,
        {
          fileId: fileId,
          userId: userId,
          status: "REVIEW_COMPLETED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Set the message
      setMessage("Completed Review successfully!");

      // Hide the message after 3 seconds
      setTimeout(() => {
        setMessage("");
        navigate("/files");
      }, 1000);
    } catch (error) {
      console.error("Error completing review:", error);
    }
  };
  const handleClick = () => {
  
    // Determine the correct return path
    const previousPage = location.state?.from || localStorage.getItem("previousPage");
    const defaultPath = roles.includes("Reviewer") ? "/files" : "/documents";
    const pathname = previousPage === "/issues" ? "/issues" : defaultPath;
    // Save navigation state
    localStorage.setItem("navigationState", JSON.stringify({ fileId: lastFileId, currentPage }));
  
    // Navigate back to the appropriate page
    navigate(pathname, {
      state: { fileId: lastFileId, currentPage, from: location.pathname }, // Store current page for future back navigation
    });
  
    localStorage.removeItem("previousPage");
  };
  
  // const handleClick = () => {
  //   const pathname = roles.includes("Reviewer") ? "/files" : "/documents";
  //   localStorage.setItem(
  //     "navigationState",
  //     JSON.stringify({ fileId: lastFileId, currentPage: currentPage })
  //   );
  //   navigate(pathname, {
  //     state: { fileId: lastFileId, currentPage: currentPage },
  //   });
  // };
  const handleAddIssue = () => {
    navigate("/add-issue", {
      state: { fileId: lastFileId, fileName: fileName },
    });
  };
  const fetchTags = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      const params = {
        page: 1,
        size: "",
        name: "",
        sortColumn: "id",
        sortOrder: "DESC",
        status: "ACTIVE",
        userId: userId,
        role: Array.isArray(roles) ? roles.join(",") : roles,
        projectId: projectId,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const parsedIssues = response.data.content.map((issue) => {
        let issueJson = {};
        try {
          if (issue.issueJson) {
            issueJson = JSON.parse(issue.issueJson);
          }
        } catch (error) {
          console.error("Error parsing issueJson:", error);
        }
        return {
          ...issue,
          issueJson,
        };
      });
      setTags(parsedIssues);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setLoading(false);
    }
  };
  const fetchIssues = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();
      const params = {
        page: 1,
        size: "",
        search: "",
        sortColumn: "id",
        sortOrder: "DESC",
        status: "ACTIVE",
        projectId: projectId,
        sectionId: "",
        subsectionId: "",
        issueNatureId: "",
        issueTypeId: "",
        templateChangeTypeId: "",
        tagId: "",
        createdBy: userId,
        fileId: fileId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/list-issue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const parsedIssues = response.data.content.map((issue) => {
        let issueJson = {};
        try {
          if (issue.issueJson) {
            issueJson = JSON.parse(issue.issueJson);
          }
        } catch (error) {
          console.error("Error parsing issueJson:", error);
        }
        return {
          ...issue,
          issueJson,
        };
      });
      setIssues(parsedIssues);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchTags().then(() => {
        fetchIssues();
      });
    }
  }, [currentPage, projectId]);
  useEffect(() => {
    if (tags.length > 0 && issues.length > 0) {
      const selectedTags = issues
        .filter((issue) => issue.presetTagId)
        .map((issue) => {
          const tag = tags.find((tag) => tag.id === issue.presetTagId);
          return tag ? { value: tag.id, label: tag.name } : null;
        })
        .filter((tag) => tag !== null);
      setSelectedTag(selectedTags);
    }
  }, [tags, issues]);

  const handleApplyTag = async (event) => {
    event.preventDefault();
    // Extract the selected presetTagIds
    const selectedPresetTagIds = selectedTag.map((selected) => selected.value);

    if (selectedPresetTagIds.length === 0) {
      setError("Please select valid preset tags.");
      return;
    }

    try {
      const token = await getCurrentToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/preset-tag-file-issues`,
        {
          fileId,
          createdBy: userId,
          presetTagIds: selectedPresetTagIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowSelect(!showSelect);
      // Set the message
      setMessage("Applied PresetTag successfully!");

      // Hide the message after 3 seconds
      setTimeout(() => {
        setMessage("");
      }, 1000);
    } catch (error) {
      console.error("Error posting issue:", error);
      // Set the error message
      setError("Error submitting issue(s). Please try again.");
      // Hide the message after 3 seconds
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  const handleToggleSelect = () => {
    setShowSelect(!showSelect);
  };
  return (
    <div className="position-relative">
      {files && files.length > 0 && (
        <div
          className="d-flex w-100 justify-content-end position-absolute bottom-0 end-0"
          style={{ zIndex: 1 }}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-prev`}>View previous file</Tooltip>}
          >
            <button
              onClick={prevFile}
              disabled={currentIndex === 0}
              className="btn-custom"
            >
              <CaretLeftFill size={26} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-next`}>View next file</Tooltip>}
          >
            <button
              onClick={nextFile}
              disabled={currentIndex === files.length - 1}
              className="btn-custom"
            >
              <CaretRightFill size={26} />
            </button>
          </OverlayTrigger>
        </div>
      )}

      <div className="py-2 d-flex justify-content-between align-items-center position-relative">
        <button
          onClick={handleClick}
          className="btn btn-sm btn-outline-primary px-3"
        >
          <ChevronDoubleLeft className="dark-icon-color" /> Back
        </button>
        <div className="d-flex justify-content-end" style={{ flexGrow: 1 }}>
          {roles.includes("Reviewer") && (
            <>
              {/* {status !== "NOISSUE" && status !== "RESOLVED" ? (
            <button className="btn btn-sm btn-primary px-3 me-2" onClick={handleAddIssue}>
              Add Issue
            </button>
            ) : null} */}
              <div>
                <button
                  className="btn btn-sm btn-outline-primary px-3 d-flex align-items-center me-2"
                  onClick={handleToggleSelect}
                >
                  Preset Tag
                  {/* {selectedTag.length > 0
                    ? "Edit Preset Tag"
                    : "Select Preset Tag"} */}
                  {showSelect ? (
                    <ChevronUp className="ms-2" />
                  ) : (
                    <ChevronDown className="ms-2" />
                  )}
                </button>
                {showSelect && (
                  <div className="tag-selection-bx position-absolute bg-white border shadow">
                    <div className="me-2 d-flex">
                      <div style={{ minWidth: "350px" }}>
                        <Select
                          options={tags.map((tag) => ({
                            value: tag.id,
                            label: tag.name,
                          }))}
                          value={selectedTag}
                          onChange={(selectedOption) =>
                            setSelectedTag(selectedOption)
                          }
                          placeholder="Select Preset Tag"
                          isMulti
                        />
                      </div>
                      <button
                        className="btn btn-sm btn-outline-primary px-3 ms-2"
                        onClick={handleApplyTag}
                      >
                        Apply Tag
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {status == "ISSUE" && (
                <button
                  className="btn btn-sm btn-outline-success px-3 me-2"
                  onClick={handleReviewCompleted}
                  disabled={status === "REVIEW_COMPLETED"}
                >
                  Review Completed
                </button>
              )}
              <button
                className="btn btn-sm btn-primary px-3 me-2"
                onClick={handleAddIssue}
              >
                Add Issue
              </button>
              {status !== "ISSUE" &&
                status !== "RESOLVED" &&
                status !== "ACTIVE" && (
                  <button
                    className="btn btn-sm btn-success px-3 me-2"
                    onClick={() => completeReview()}
                    disabled={
                      reviewCompleted ||
                      status === "NOISSUE" ||
                      status === "RESOLVED"
                    }
                  >
                    {reviewCompleted ||
                    status === "NOISSUE" ||
                    status === "RESOLVED"
                      ? "No Issue Found"
                      : "No Issue"}
                  </button>
                )}
            </>
          )}

          {/* <button
            className="btn btn-sm btn-primary px-3 me-2"
            onClick={() => handleDownloadZip()}
          >
            <Download size={19} />
          </button> */}
          {!isFirstColumnExpanded &&
            !isSecondColumnExpanded &&
            projectType !== "PREVIEW_PROJECT" && (
              <button
                className="btn btn-secondary btn-sm px-3"
                onClick={() => setIsThirdColumnVisible(!isThirdColumnVisible)}
              >
                {isThirdColumnVisible ? (
                  <>
                    <ArrowsAngleContract /> Hide All Files
                  </>
                ) : (
                  <>
                    <ArrowsAngleExpand /> Show All Files
                  </>
                )}
              </button>
            )}
        </div>
      </div>
      {message && (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      )}
      <h6 className="mb-2">{fileName}</h6>
      <div className="row flex-1 fetched-content">
        {projectType !== "PREVIEW_PROJECT" && (
          <>
            {!isSecondColumnExpanded && (
              <div
                className={`col-md-${
                  isFirstColumnExpanded
                    ? "12"
                    : !isThirdColumnVisible
                    ? "6"
                    : "5"
                }`}
              >
                <div className="border rounded p-3 pt-5 h-100 d-flex flex-column position-relative">
                  <button
                    className="btn btn-sm btn-primary px-3 position-absolute top-0 end-0"
                    style={{ zIndex: 1 }}
                    onClick={() =>
                      setIsFirstColumnExpanded(!isFirstColumnExpanded)
                    }
                  >
                    {isFirstColumnExpanded ? (
                      <ArrowsAngleContract />
                    ) : (
                      <ArrowsAngleExpand />
                    )}
                  </button>
                  {!blobURL ? (
                    <p className="text-center fw-bold m-auto">
                      Click on file names in the file tree, you will see the
                      files here
                    </p>
                  ) : (
                    <div
                      className="overflow-y-auto d-flex flex-column h-100"
                      style={{ maxHeight: "70vh" }}
                    >
                      {fileType === "jpg" ||
                      fileType === "png" ||
                      fileType === "gif" ? (
                        <img
                          src={blobURL}
                          alt="File content"
                          className="img-fluid my-auto cursor-pointer"
                          onClick={() => setIsModalOpen(true)}
                        />
                      ) : fileType === "pdf" ? (
                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                          <div className="position-absolute top-0 start-0 w-100 d-flex justify-content-center pt-1">
                            <ZoomOutButton />
                            <ZoomPopover />
                            <ZoomInButton />
                          </div>
                          <div
                            style={{
                              flex: 1,
                              overflow: "hidden",
                            }}
                          >
                            <Viewer
                              fileUrl={blobURL}
                              plugins={[zoomPluginInstance]}
                            />
                          </div>
                        </Worker>
                      ) : fileType === "json" ? (
                        (() => {
                          try {
                            return (
                              <>
                                {isLoading ? (
                                  <>Loading</>
                                ) : (
                                  <pre>
                                    {JSON.stringify(
                                      clickedFileContent,
                                      null,
                                      2
                                    )}
                                  </pre>
                                )}
                              </>
                            );
                          } catch (error) {
                            return <pre>Invalid JSON</pre>;
                          }
                        })()
                      ) : (
                        <pre>{blobURL}</pre>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isFirstColumnExpanded && (
              <div
                className={`col-md-${
                  isSecondColumnExpanded
                    ? "12"
                    : !isThirdColumnVisible
                    ? "6"
                    : "4"
                }`}
              >
                <div className="border rounded p-3 pt-5 h-100 position-relative">
                  <button
                    onClick={() =>
                      setIsSecondColumnExpanded(!isSecondColumnExpanded)
                    }
                    className="btn btn-sm btn-primary px-3 position-absolute top-0 end-0"
                  >
                    {isSecondColumnExpanded ? (
                      <ArrowsAngleContract />
                    ) : (
                      <ArrowsAngleExpand />
                    )}
                  </button>
                  {isLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <div
                      className="overflow-y-auto d-flex h-100"
                      style={{ maxHeight: "70vh" }}
                    >
                      {jsonContent && typeof jsonContent === "object" ? (
                        <JsonViewer jsonData={jsonContent} fileId={fileId} />
                      ) : (
                        // <ReactJson
                        // src={jsonContent}
                        // name={false}
                        // displayDataTypes={false}
                        // displayObjectSize={false}
                        // enableClipboard={false}
                        // theme={{
                        //     base00: 'var(--json-base00)',
                        //     base01: 'var(--json-base01)',  // Light background color
                        //     base02: 'var(--json-base02)',  // Medium background color
                        //     base03: 'var(--json-base03)',  // Dark background color
                        //     base04: 'var(--json-base04)',  // Text color
                        //     base05: 'var(--json-base05)',  // Highlight color
                        //     base06: 'var(--json-base06)',  // Border color
                        //     base07: 'var(--json-base07)',  // White color
                        //     base08: 'var(--json-base08)',  // Red color
                        //     base09: 'var(--json-base09)',  // Orange color
                        //     base0A: 'var(--json-base0A)',  // Yellow color
                        //     base0B: 'var(--json-base0B)',  // Green color
                        //     base0C: 'var(--json-base0C)',  // Cyan color
                        //     base0D: 'var(--json-base0D)',  // Blue color
                        //     base0E: 'var(--json-base0E)',  // Purple color
                        //     base0F: 'var(--json-base0F)'   // Pink color
                        //    }}
                        // />
                        <p>No JSON content available</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {isThirdColumnVisible &&
              !isFirstColumnExpanded &&
              !isSecondColumnExpanded && (
                <div className="col-md-3">
                  <div className="border rounded p-3 h-100">
                    <ul
                      className="pure-tree main-tree list-unstyled overflow-y-auto m-0"
                      style={{ maxHeight: "78vh" }}
                    >
                      {buildFileTree(fileTree)}
                    </ul>
                  </div>
                </div>
              )}
          </>
        )}
        {projectType === "PREVIEW_PROJECT" && (
          <div className="col-md-12">
            <div
              className="border rounded p-3 pt-5 h-100 d-flex flex-column position-relative overflow-y-auto"
              style={{ maxHeight: "78vh" }}
            >
              {pdfView ? (
                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                  <div className="position-absolute top-0 start-0 w-100 d-flex justify-content-center pt-1">
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                    }}
                  >
                    <Viewer
                      fileUrl={pdfView}
                      plugins={[zoomPluginInstance]}
                      onDocumentLoadFailed={(e) => setError(e.message)}
                    />
                  </div>
                </Worker>
              ) : (
                <p className="text-center fw-bold m-auto">No PDF file found</p>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        className="enlarge-modal"
        scrollable={true}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <TransformWrapper>
            <TransformComponent>
              <img src={blobURL} alt="File content" className="img-fluid" />
            </TransformComponent>
          </TransformWrapper>
        </Modal.Body>
      </Modal>

      {draggableDivVisible && (
        <Rnd
          default={{
            x: window.innerWidth / 6, // Move more to the left
            y: 0, // Move more to the top
            width: Math.min(window.innerWidth * 0.8, 600),
            height: window.innerHeight * 0.8, // Initial height
          }}
          minWidth={200}
          minHeight={100}
          bounds="#root" // Change this line to bound the component within the root element
          className="position-absolute d-flex draggable-container"
          dragHandleClassName="drag-handle" // Only elements with this class can be used to drag
          onDragStart={() => {
            document.getElementById("root").classList.add("drag-no-select");
          }}
          onDragStop={() => {
            document.getElementById("root").classList.remove("drag-no-select");
          }}
          onResize={(e, direction, ref, delta, position) => {
            // Update height of the content dynamically
            const content = ref.querySelector(".draggable-content");
            if (content) {
              content.style.height = `${
                ref.clientHeight -
                ref.querySelector(".draggable-header").clientHeight
              }px`;
            }
          }}
        >
          <div className="draggable-header drag-handle">
            <span className="drag-file-title">{selectedFileName}</span>
            <i
              onClick={() => {
                setDraggableDivVisible(false);
              }}
              className="drag-close-button"
            >
              <XCircleFill />
            </i>
          </div>

          <div className="draggable-content">
            {clickedFileContent &&
            (draggableFileType === "jpg" || draggableFileType === "png") ? (
              <div className="drag-image-container">
                <TransformWrapper key={popUpZoomKey} ref={transformWrapperRef}>
                  <TransformComponent>
                    <img
                      src={clickedFileContent}
                      alt="File content"
                      className="drag-image"
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ) : draggableFileType === "pdf" ? (
              <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                <span className="drag-pdf-controls">
                  <ZoomOutButtonPopup />
                  <ZoomPopoverPopup />
                  <ZoomInButtonPopup />
                </span>
                <div className="drag-pdf-container">
                  <Viewer
                    fileUrl={clickedFileContent}
                    plugins={[popupzoomPluginInstance]}
                  />
                </div>
              </Worker>
            ) : null}
          </div>
        </Rnd>
      )}
    </div>
  );
}

export default FileViewer;
