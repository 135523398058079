import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import { getCurrentUser } from "../services/cognito/cognitoAuth";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchUserInfo = async () => {
  const token = await getCurrentToken();
  const user = await getCurrentUser();
  const cognitoSub = user.sub;
  try {
    const userResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return userResponse.data;
  } catch (error) {
    console.error("Error fetching user/project info:", error);
  }
};

