import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import Select from "react-select";
import { Percent, Calculator } from "react-bootstrap-icons";

function SpecificPassPercentage() {
  const [passPercentage, setPassPercentage] = useState([]);
  const { projectId, userId, roles } = useContext(ProjectContext);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);

  const fetchTags = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      const params = {
        page: 1,
        size: "",
        name: "",
        sortColumn: "id",
        sortOrder: "DESC",
        status: "ACTIVE",
        userId: userId,
        role: Array.isArray(roles) ? roles.join(",") : roles,
        projectId: projectId,
        isAssignedIssue: true,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const parsedIssues = response.data.content.map((issue) => {
        let issueJson = {};
        try {
          if (issue.issueJson) {
            issueJson = JSON.parse(issue.issueJson);
          }
        } catch (error) {
          console.error("Error parsing issueJson:", error);
        }
        return {
          ...issue,
          issueJson,
        };
      });
      setTags(parsedIssues);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setLoading(false);
    }
  };
  const fetchPassPercentage = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/pass-percent-for-presets`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            projectId: projectId,
            presetIds: selectedTag.map((tag) => tag.value).join(","),
          },
        }
      );

      setPassPercentage(response.data);
    } catch (error) {
      console.error("Error fetching pass percentage:", error);
    }
  };
  useEffect(() => {
    fetchPassPercentage();
  }, [selectedTag]);
  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <div className="mb-3">
        <h5 className="fw-bold mb-3">Pass Rate Simulator</h5>
      <div className="mb-3">
        <Select
          options={tags.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))}
          value={selectedTag}
          onChange={(selectedOption) => setSelectedTag(selectedOption)}
          placeholder="Select Preset Tag"
          isMulti
        />
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="shadow p-3 bg-white rounded d-flex align-items-center mb-3">
            <div className="icon-bx"><Calculator size={20} /></div>
            <div className="box-content">
              <p className="fw-bold mb-0">Files Fully Resolved</p>
              <h4 className="fw-bold text-dark mb-0">
                {passPercentage.totalNoIssueFiles}
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="shadow p-3 bg-white rounded d-flex align-items-center h-100">
            <div className="icon-bx"><Percent size={20} /></div>
            <div className="box-content">
              <p className="fw-bold mb-0">Pass Percentage Achieved</p>
              <h4 className="fw-bold text-dark mb-0">
                {passPercentage.passPercentage}%
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecificPassPercentage;
