import React, { useEffect, useState, useContext } from "react";
import { getCurrentUser, signOut } from "../../services/cognito/cognitoAuth";
import { Dropdown } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AuthContext from "../auth/AuthContext";
import { deRegisterToken } from "../../services/firebase/firebaseConfig";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getApps, getApp } from "firebase/app";
import { List } from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectContext from "../../ProjectContext";
import SwitchTenant from "../tenant/SwitchTenant";
import SwitchProject from "../projects/SwitchProject";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import { fetchUserInfo } from "../../api/apiService";

function Header() {
  const [user, setUser] = useState();
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(AuthContext);
  const app = getApps()[0] || getApp();
  const messaging = getMessaging(app);
  const [showProjectDisabledModal, setShowProjectDisabledModal] =
    useState(false);
  const [projectDisabledMessage, setProjectDisabledMessage] = useState("");
  const {
    setProjectId,
    setProjectName,
    isProcessing,
    setIsProcessing,
    setRefreshFiles,
    setCurrentPage,
    roles,
    setRefreshProcessed,
    userId,
    theme,
    setTheme,
  } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [signingOut, setSigningOut] = useState(false);

  const toggleTheme = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}user/theme-update`,
        {
          userId: userId,
          isDarkThemeActive: theme === "light" ? true : false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Fetch updated user info after successful theme update
      const updatedUserInfo = await fetchUserInfo(userId, token);

      // Update the state with the new theme
      setTheme(updatedUserInfo.isDarkThemeActive ? "dark" : "light");
    } catch (error) {
      console.error("Error updating theme:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.body.className = theme + "-theme";
  }, [theme]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setUser(user);
      } catch (err) {
        console.error(err);
        if (err.message === "Refresh Token has expired") {
          await signOut();
          setAuthenticated(false);
          navigate("/login");
        }
      }
    };

    fetchUser();

    // Listen for incoming FCM messages
    const unsubscribe = onMessage(messaging, (payload) => {
      // Process your message as desired
      setNotifications((oldNotifications) => [...oldNotifications, payload]);

      // Show a toast notification
      toast(JSON.stringify(payload.data.message));
      if (payload.data.type === "PROJECT DISABLED") {
        setProjectDisabledMessage(
          payload.data.message || "Current project is disabled."
        );
        setShowProjectDisabledModal(true);
      }
      // if (payload.data.message) {
      //   setIsProcessing(false);
      //   setRefreshFiles(true);
      // }
      if (payload.data.message) {
        setIsProcessing(false);
        setRefreshFiles(true);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  const handleSignOut = async () => {
    setSigningOut(true); // Show loader
    try {
      if (Notification.permission === "granted") {
        const fcmToken = await getToken(messaging);
        if (fcmToken) {
          await deRegisterToken(fcmToken, user.sub);
        }
      }
      await signOut();
      navigate("/login");
      setUser(null);
      setAuthenticated(false);
      setProjectId(null);
      setProjectName(null);
      localStorage.clear();
      setCurrentPage(1);
    } catch (error) {
      console.error("Error signing out:", error);
    } finally {
      setSigningOut(false); // Hide loader
    }
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };
  return (
    <>
      <ToastContainer />
      <Modal
        show={showProjectDisabledModal}
        onHide={() => {
          setShowProjectDisabledModal(false);
          window.location.reload(); // Refresh the page on modal close
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5 className="fw-bold">Project Disabled</h5>
        </Modal.Header>
        <Modal.Body>
          <p>
            {projectDisabledMessage}. Switch to another project to continue.
          </p>

          <SwitchProject />
        </Modal.Body>
      </Modal>

      <header className="navbar navbar-expand-lg">
        <div className="container-fluid justify-content-end align-items-center">
          {(roles.includes("ProjectAdmin") || roles.includes("Reviewer")) && (
            <SwitchProject />
          )}
          {user && (
            <Dropdown className="profile-info">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="text-uppercase rounded-pill"
              >
                {user.username.charAt(0)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="px-3 pb-2">
                  Hi{" "}
                  <span className="fw-bold text-primary">{user.username}</span>
                  <div className="fs-sm fw-bold text-uppercase">{roles}</div>
                  {!(
                    roles.includes("SuperAdmin") || roles.includes("Developer")
                  ) && <SwitchTenant />}
                </div>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleChangePassword}>
                  Change Password
                </Dropdown.Item>
                {/* <Dropdown.Item href="#">Turn on MFA</Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleSignOut} disabled={signingOut}>
                  {signingOut ? "Signing out..." : "Logout"}
                </Dropdown.Item>

                <Dropdown.Divider />

                <div className="toggle-container">
                  <label>Light</label>
                  <input
                    type="checkbox"
                    id="theme-toggle"
                    className="theme-toggle"
                    checked={theme === "dark"}
                    disabled={loading}
                    onChange={toggleTheme}
                  />
                  <label
                    htmlFor="theme-toggle"
                    className="toggle-label"
                  ></label>
                  <label>Dark</label>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </header>
    </>
  );
}

export default Header;
