import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
//import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables, ChartDataLabels);

function ReviewersProgressChart({ startDate, endDate }) {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  
  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );

      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/viewed-noreviewed?`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userIds: filterCreatedBy || selectedUser?.value || "",
            projectId: projectId,
            startDate: startDate.toISOString().split("T")[0] + " 00:00:00",
            endDate: endDate.toISOString().split("T")[0] + " 00:00:00",
          },
        }
      );

      const data = response.data;
      setChartData({
        labels: data.map((item) => `${item.user}`),
        datasets: [
          {
            label: "Reviewed Percentage",
            data: data.map((item) => item.reviewPercentage), // PERCENTAGE DATA
            countData: data.map((item) => item.reviewedCount), // COUNT DATA
            backgroundColor: "#1bc48c", // Green for reviewed
          },
          {
            label: "Not Reviewed Percentage",
            data: data.map((item) => item.noReviewPercentage), // PERCENTAGE DATA
            countData: data.map((item) => item.notReviewedCount), // COUNT DATA
            backgroundColor: "#f44336", // Red for not-reviewed
          },
        ],
  
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (roles.includes("ProjectAdmin")) {
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);

  useEffect(() => {
    fetchData();
  }, [projectId, startDate, endDate, selectedUser, filterCreatedBy]);

  const colClassName = roles.includes("Reviewer") ? "col-md-3" : "col-md-6";
  return (
    <div>
      <h5 className="fw-bold">Reviewers Progress</h5>
      <div className="row g-2 mb-3">
        <div className="col-md-12">
          <Select
            options={users.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            value={selectedUser}
            isClearable
            placeholder="Select User"
            className=""
            onChange={(selectedOption) => {
              setSelectedUser(selectedOption);
              setFilterCreatedBy(selectedOption ? selectedOption.value : "");
            }}
          />
        </div>
      </div>
      {chartData ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`,
                },
              },
              legend: {
                position: "top",
              },
              datalabels: {
                color: "#fff", // Set the color of the label
                align: "start",
                anchor: "end",
                formatter: (value, context) => {
                  const dataset = context.dataset;
                  const dataIndex = context.dataIndex;
              
                  // Ensure countData exists and has the required index
                  if (dataset.countData && dataset.countData[dataIndex] !== undefined) {
                    return dataset.countData[dataIndex]; // Show actual count value
                  } else {
                    return ""; // Return empty string if countData is missing
                  }
                },
              
        
              },
            },
            scales: {
              x: {
                stacked: true, // Enable stacking
                title: {
                  display: true,
                  text: "Users",
                },
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                stacked: true, // Enable stacking
                title: {
                  display: false,
                  //text: "Percentage",
                },
                ticks: {
                  callback: (value) => `${value}%`,
                  beginAtZero: true,
                },
                grid: {
                  display: false,
                },
              },
            },
          }}
        />
      ) : (
        <p className="text-center">Loading data...</p>
      )}
    </div>
  );
}

export default ReviewersProgressChart;
