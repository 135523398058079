import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";
import { formatDateWithoutTime } from "../../utils";

function LastAssignedDate() {
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [lastAssignDates, setLastAssignDates] = useState([]);

  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );

      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchLastAssignDate = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/assign-date`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            projectId: projectId,
          },
        }
      );

      const data = response.data;
      setLastAssignDates(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (roles.includes("ProjectAdmin")) {
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);

  useEffect(() => {
    fetchLastAssignDate();
  }, [projectId]);

  const filteredAssignDates =
    selectedUsers.length > 0
      ? lastAssignDates.filter((item) =>
          selectedUsers.some((user) => user.value === item.userId)
        )
      : lastAssignDates;
  
  return (
    <div>
      <>
        {roles.includes("ProjectAdmin") && (
          <>
            <div className="mb-2">
              <Select
                options={users.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`,
                }))}
                value={selectedUsers}
                isClearable
                isMulti
                placeholder="Select User"
                className=""
                onChange={(selectedOptions) => {
                  setSelectedUsers(selectedOptions || []);
                }}
              />
            </div>

            <ul className="list-unstyled mb-0 date-list">
              {filteredAssignDates.length > 0 ? (
                filteredAssignDates.map((item) => (
                  <li key={item.userId} className="mb-2">
                    <div className="text-muted fs-6">
                      {item.user} 
                      {item.status === "INACTIVE" ? (
                        <span className="badge bg-danger fs-xs ms-1">
                          {item.status}
                        </span>
                      ) : null}
                    </div>
                    <h6 className="fw-bold">
                      {formatDateWithoutTime(item.assignedDate)}
                    </h6>
                  </li>
                ))
              ) : (
                <li>-</li>
              )}
            </ul>
          </>
        )}
        {roles.includes("Reviewer") && (
          <h4 className="fw-bold text-dark mb-0">
            {lastAssignDates.length > 0
              ? formatDateWithoutTime(
                  lastAssignDates.find((item) => item.userId === userId)
                    ?.assignedDate
                )
              : "-"}
          </h4>
        )}
      </>
    </div>
  );
}

export default LastAssignedDate;
